export const createFetchNavigateAsPersonsAction = () => {
    return {
        type: '@PERSONS/FETCH_NAVIGATE_AS_PERSONS',
    } as const;
};

export const createFetchNavigateAsPersonsSuccessAction = (icIds: string[]) => {
    return {
        type: '@PERSONS/FETCH_NAVIGATE_AS_PERSONS_SUCCESS',
        payload: {
            icIds,
        },
    } as const;
};

export const createFetchNavigateAsPersonsFailureAction = () => {
    return {
        type: '@PERSONS/FETCH_NAVIGATE_AS_PERSONS_FAILURE',
    } as const;
};

export const createFetchPersonsAction = (icIds: string[]) => {
    return {
        type: '@PERSONS/FETCH_PERSONS',
        payload: {
            icIds,
        },
    } as const;
};

export const createFetchPersonsSuccessAction = (icIds: string[]) => {
    return {
        type: '@PERSONS/FETCH_PERSONS_SUCCESS',
        payload: {
            icIds,
        },
    } as const;
};

export const createFetchPersonsFailureAction = (icIds: string[]) => {
    return {
        type: '@PERSONS/FETCH_PERSONS_FAILURE',
        payload: {
            icIds,
        },
    } as const;
};

export type PersonsAction =
    | ReturnType<typeof createFetchNavigateAsPersonsAction>
    | ReturnType<typeof createFetchNavigateAsPersonsSuccessAction>
    | ReturnType<typeof createFetchNavigateAsPersonsFailureAction>
    | ReturnType<typeof createFetchPersonsAction>
    | ReturnType<typeof createFetchPersonsSuccessAction>
    | ReturnType<typeof createFetchPersonsFailureAction>;
