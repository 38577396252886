import CalendarTodayIcon from '@mui/icons-material/Today';
import React, { FC, useState, MouseEvent } from 'react';
import { DatePicker as MuiDatePicker, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { ClickAwayListener } from '@mui/material';
import { InputPicker } from './InputPicker';
import { Dayjs, dayjstz } from 'services/Date';
import classNames from 'classnames';

export interface DatePickerProps {
    id?: string;
    className?: string;
    minDate?: Date | string;
    maxDate?: Date | string;
    value: Date | string | null;
    onChange: (date: string | null) => void;
}

export const DatePicker: FC<DatePickerProps> = ({ id, className, minDate, maxDate, value, onChange }) => {
    const [open, setOpen] = useState(false);
    const handleClickAway = () => {
        if (open) {
            setOpen(false);
        }
    };

    const handleOnClick = (event: MouseEvent<HTMLInputElement>) => {
        // Fix focus issue :  with "setOpen", focus is lost
        const currentTarget = event.currentTarget;
        setTimeout(() => currentTarget.focus(), 10);

        if (!open) {
            setOpen(true);
        }
    };

    const handleClear = () => {
        onChange(null);
    };

    const handleChange = (date: Dayjs | null, keyboardInputValue: string | undefined) => {
        onChange(date?.isValid() && date?.tz(undefined, true).startOf('day').format() || keyboardInputValue || null);
        setOpen(false);
    };

    const toDayjsValue = (date: Date | string | null | undefined) => dayjstz(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone, true);

    const renderPickerDay = (
        date: Dayjs,
        selectedDates: Array<Dayjs | null>,
        pickersDayProps: PickersDayProps<Dayjs>,
    ) => {
        if (!value) {
            return <PickersDay
                {...pickersDayProps}
                className={classNames('font-weight-medium btn p-0 text-large rounded-0 m-0')}
                style={{ width: '40px', height: '40px' }}
            />;
        }

        const isSelectedDay = date.isSame(value, 'day');

        return (
            <PickersDay
                {...pickersDayProps}
                className={classNames('font-weight-medium btn p-0 text-large rounded-0 m-0', {
                    'btn-primary': isSelectedDay,
                })}
                selected={isSelectedDay}
                style={{ width: '40px', height: '40px' }}
            />
        );
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway} >
            <MuiDatePicker
                componentsProps={{
                    actionBar: {
                        actions: (variant) => (variant === 'desktop' ? [] : ['cancel', 'accept']),
                    },
                }}
                open={open}
                maxDate={maxDate && dayjstz(maxDate).isValid() ? toDayjsValue(maxDate) : undefined}
                minDate={minDate && dayjstz(minDate).isValid() ? toDayjsValue(minDate) : undefined}
                PopperProps={{
                    placement: 'bottom-start',
                }}
                inputFormat="DD-MM-YYYY"
                mask="__-__-____"
                renderInput={({ inputRef, inputProps }) => (
                    <InputPicker
                        id={id}
                        className={classNames('text-primary py-0 bg-transparent border-0 flex-grow-1 h-100', className)}
                        inputRef={inputRef}
                        inputProps={inputProps}
                        inputStyle={{ width: '12em' }}
                        onInputClick={handleOnClick}
                        onClearClick={handleClear}
                        onIconClick={() => setOpen(!open)}
                        iconType={CalendarTodayIcon}
                    />
                )}
                renderDay={renderPickerDay}
                value={dayjstz(value).isValid() ? toDayjsValue(value) : value}
                onChange={handleChange}
            />
        </ClickAwayListener>
    );
};
