import React from 'react';
import { ToastContentProps } from 'react-toastify';
import { formatDatetime } from 'services/Date';

export type ToastMessageProps = Partial<ToastContentProps> & {
    title?: string;
    message?: string;
};

export const ToastMessage: React.FC<ToastMessageProps> = ({ title, message, closeToast, toastProps }) => {
    let toastTitle = title;
    if (!title) {
        switch (toastProps?.type) {
            case 'error':
                toastTitle = 'Error';
                break;

            case 'warning':
                toastTitle = 'Warning';
                break;

            case 'success':
                toastTitle = 'Success';
                break;

            default:
                toastTitle = 'Information';
                break;
        }
    }

    return (
        <>
            <div className="toast-header d-flex">
                <span className="w-100 d-flex justify-content-between flex-fill">
                    <strong>{toastTitle}</strong>
                    <span className="text-secondary fw-medium">
                        {formatDatetime(Date.now())}
                    </span>
                </span>
                <button type="button" className="ms-2 mb-1 btn-close" data-dismiss="toast" aria-label="Close" onClick={closeToast} />
            </div>
            {message && <div className="toast-body text-primary">{message}</div>}
        </>
    );
};
